import FofaLogo from "@/assets/img/logo-fofa.png";
import safeLineLogo from "@/assets/img/logo-safeLine.png";
export default {
  name: "GobyWebFEIndex",

  data() {
    return {
      FofaLogo,
      safeLineLogo
    };
  },

  mounted() {
    console.log("this is index page");
  },

  methods: {}
};