import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1417837a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  href: "https://fofa.info",
  target: "_blank"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  href: "https://rivers.chaitin.cn/product/co2hvtla60nc73dirk3g?rc=BSYHBD2Q556NSZ3MBPZDNJUHGOATYDUS",
  target: "_blank"
};
const _hoisted_6 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_card = _resolveComponent("el-card");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Friendship Link')), 1), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_3, [_createVNode(_component_el_card, {
    class: "custom-card"
  }, {
    default: _withCtx(() => [_createElementVNode("img", {
      src: $data.FofaLogo
    }, null, 8, _hoisted_4), _createTextVNode(_toDisplayString(_ctx.$t('Network space surveying and mapping search engine')), 1)]),
    _: 1
  })]), _createElementVNode("a", _hoisted_5, [_createVNode(_component_el_card, {
    class: "custom-card"
  }, {
    default: _withCtx(() => [_createElementVNode("img", {
      src: $data.safeLineLogo
    }, null, 8, _hoisted_6), _createTextVNode(" " + _toDisplayString(_ctx.$t('The world\'s leading web security protection product')), 1)]),
    _: 1
  })])])]);
}