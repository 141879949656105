import FriendLink from '@/views/friendLink/index.vue'

export default {
  routers: [
		{
			path: '/friendLink',
			name: 'friendLink',
			component: FriendLink
		},
	]
}